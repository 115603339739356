<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.name"
    class="px-5 py-3"
    v-if="detail"
  >
    <base-detail-action-button
      :permissions="permissions"
      :basePath="basePath"
      :id="detail.id"
      v-if="detail.id"
      @deleteAction="deleteAction"
    >
    </base-detail-action-button>
    <v-row>
      <v-col cols="6" class="border--right">
        <v-row>
          <v-col cols="6">
            {{ $t('unitCategory.name') }}
          </v-col>
          <v-col cols="6">
            {{ detail.name }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row></v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['detail', 'permissions'],
  computed: {
    ...mapState({
      basePath: (state) => state.unitCategory.basePath,
    }),
  },
  methods: {
    async deleteAction(id) {
      try {
        await this.$store.dispatch('unitCategory/delete', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('unitCategory.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
